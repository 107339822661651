import { useEffect, useState } from 'react';
import { useQuiz } from '../../context/QuizContext';
import { ScreenTypes } from '../../types';
import QuestionScreen from '../QuestionScreen';
import QuizDetailsScreen from '../QuizDetailsScreen';
import QuizTopicsScreen from '../QuizTopicsScreen';
import ResultScreen from '../ResultScreen';
import SplashScreen from '../SplashScreen';
import styled from 'styled-components';

// Stili per il pop-up
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  max-width: 600px; /* Pop-up più grande */
  width: 90%;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #000;
  }
`;

const PopupImage = styled.img`
  width: 120px; /* Immagine più grande */
  height: 120px;
  margin: 0 auto 25px; /* Centra l'immagine */
  display: block;
`;

const PopupTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
`;

const PopupText = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const HighlightText = styled.span`
  color: #007bff;
  font-weight: 600;
`;

const PopupButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
`;

function Main() {
  const { currentScreen, setCurrentScreen } = useQuiz();
  const [showPopup, setShowPopup] = useState(false);

  // Mostra il pop-up dopo 5 secondi
  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(popupTimer);
  }, []);

  // Navigazione iniziale (esempio: da SplashScreen a QuizTopicsScreen)
  useEffect(() => {
    const screenTimer = setTimeout(() => {
      setCurrentScreen(ScreenTypes.QuizTopicsScreen);
    }, 1000);

    return () => clearTimeout(screenTimer);
  }, [setCurrentScreen]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const screenComponents = {
    [ScreenTypes.SplashScreen]: <SplashScreen />,
    [ScreenTypes.QuizTopicsScreen]: <QuizTopicsScreen />,
    [ScreenTypes.QuizDetailsScreen]: <QuizDetailsScreen />,
    [ScreenTypes.QuestionScreen]: <QuestionScreen />,
    [ScreenTypes.ResultScreen]: <ResultScreen />,
  };

  const ComponentToRender = screenComponents[currentScreen] || <SplashScreen />;

  return (
    <>
      {ComponentToRender}

      {showPopup && (
        <PopupOverlay>
          <PopupContent>
            <CloseButton onClick={closePopup}>&times;</CloseButton>
            <PopupImage src="https://appuntipillole.it/logo.png" alt="Appunti in Pillole" />
            <PopupTitle>Il team di <HighlightText>Quiz Diritto</HighlightText> presenta: <br />
            "Appunti in Pillole"!</PopupTitle>
            <PopupText>
              Con <HighlightText>Appunti in Pillole</HighlightText>, puoi trasformare i tuoi PDF in:
              <br />
              <HighlightText>audio-pillole</HighlightText> per ascoltare i contenuti ovunque,<br />
              <HighlightText>mappe concettuali</HighlightText> per visualizzare le informazioni in modo chiaro,<br />
              e <HighlightText>flashcard</HighlightText> per memorizzare velocemente i concetti chiave!
            </PopupText>
            <PopupButton onClick={() => (window.location.href = 'https://appuntipillole.it')}>
              Scopri di più
            </PopupButton>
          </PopupContent>
        </PopupOverlay>
      )}
    </>
  );
}

export default Main;